import MainLayout from '@/shared/components/mainLayout/MainLayout';
import { AppContext } from 'context/appContext/AppContextProvider';
import ProductosPedidos from './ProductosPedidos';
import ComprasB2b from '@/shared/components/comprasB2b/ComprasB2b';
import CardCategorie from '@/shared/components/cardCategories/cardCategories';
import styles from './styles.module.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { Order } from '@commercetools/platform-sdk';
import { ITableCard, initialDataTable, mapStatesOrders } from '@/shared/interfaces/ITableCard';
import { ComprasB2BIconBlue, ListaCompras, ListaComprasEmpty, B2CEmptyStateOrdersIcon, NuevasSolicitudesIcon } from '@/shared/icons';
import { IOrderStatus, StatusMapOrders, OrderInformation } from '../shared/interfaces/ITableCard';
import { formatCurrency } from '@/shared/functions/currencies';
import { useRouter } from 'next/router';
import { notifyError } from '@/shared/components/toast/Toast';
import Carousel from './CarouselB2b';
import Skeleton from '@/shared/components/skeleton/Skeleton';
import { ICustomField } from '@/shared/interfaces/IShoppingList';
import HomeShoppingList from './HomeShoppingList';
import { LOCALIZED_STRING } from '../shoppingListDetails/ShoppingListDetails';
import {
  DefineStatusCommercetools,
  DefStatusOrder,
  GetStatusByOrderLess,
  IStatusCustom,
} from '@/shared/functions/status';
import CONST_REDIRECT from '@/shared/consts/CONST_REDIRECTS.json';
import { ENVIRONMENT } from "@/shared/consts/CONST_PROJECT.json"
import CardOrdenesCompras from '@/shared/components/cardOrdenesCompras/CardOrdenesCompras';
import { ICardOrdenesCompras } from '@/shared/interfaces/ICardOrdenesCompras';
import EcommerceB2C from '../ecommerceB2C/EcommerceB2C';
import EcommerceB2CDetails from '../ecommerceB2CDetails/EcommerceB2CDetails';
import { ctCustomer } from '@/shared/client';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import WhatsAppBussiness from '@/shared/components/whatsappBussiness/WhatsAppBussiness';
import { rol } from '@/shared/roles/featureFlags';

export interface IShoppingListDataGrid {
  name: string;
  articles: number;
  total: string;
  totalNumber: number;
}

export interface IOrderData {
  orderNumber: string;
  status: string;
  colorStatus: string;
  articles: number;
  total: string;
  totalNumber: number;
}

const Home = () => {
  const context = useContext(AppContext);
  const router = useRouter();
  const [tablesorders, setTableOrders] = useState<ITableCard>();
  const [shoppingListData, setShoppingListData] = useState<IShoppingListDataGrid[]>();
  const [dataTable, setDataTable] = useState<IOrderStatus[]>(initialDataTable);
  const [cardsB2C, setCardsB2C] = useState<ICardOrdenesCompras[]>([]);
  const [b2COrders, setB2COrders] = useState<Order[]>([]);

  //checar este paddingElement añadido en merge solo la variable sin su declaracion
  const paddingElement: string = '0px';

  const showRotoplasCreditNotificationStatus = () => {
    const req = context.appContext.dataLimiteCredito;
    if (req && req.statusCode == 400) {
      notifyError('no se tiene asignado un id externo para el usuario');
    } else if (!req || req.statusCode! >= 300) {
      console.error('error fetching credit: ', req);
      notifyError('Cliente bloqueado por límite de crédito');
    } else if (req.codeBloqueo !== 0)
      if (req.msjBloqueo?.toLocaleLowerCase() !== 'no bloqueado') {
        notifyError(req?.msjBloqueo!.charAt(0).toUpperCase() + req?.msjBloqueo?.slice(1) || '');
      }
  };

  const mapShoppingListData = () => {
    const listaComprasGet = context.appContext.shoppingLists.slice(0, 8);
    const shoppingListData = listaComprasGet?.map((item) => {
      let total = 0;
      item?.lineItems?.forEach((lineItem) => {
        const fieldsItems = lineItem?.custom?.fields?.['sucursalItemReturn'];
        const fields = fieldsItems?.map((item: any) => JSON.parse(item));
        fields?.map((item: ICustomField) => {
          total += parseFloat(item.total);
        });
      });
      return {
        name: item.name[LOCALIZED_STRING],
        articles: item.lineItems.length,
        total: formatCurrency(total / 100) || '0',
        totalNumber: total / 100,
      };
    });

    if (shoppingListData.length) setShoppingListData(shoppingListData);
  };

  const mapOrdersData = async () => {
    const orders = context.appContext.orders;

    const updateQuantity = (typeOrder: string) => {
      const itemOrderState = dataTable?.find((item) => item.TypeOrder === typeOrder);
      if (!itemOrderState) return;
      itemOrderState.Number++;
      setDataTable([...dataTable, itemOrderState]);
    };
    orders?.forEach((item) => {
      let orderData: {
        Color: string;
        TypeOrder: string;
        Number: number;
      };
      if (item.orderState == 'Cancelled') {
        orderData = StatusMapOrders[30];
        updateQuantity(orderData.TypeOrder);
        return;
      }
      if (item.shipmentState == 'Backorder') {
        orderData = StatusMapOrders[100];
        updateQuantity(orderData.TypeOrder);
        return;
      }
      if (
        !item?.custom?.fields?.['Descripcion-estatus'] ||
        item?.custom?.fields?.['Descripcion-estatus']?.length <= 0
      ) {
        return;
      }
      const newCustom: string = item?.custom?.fields['Descripcion-estatus'].replace(/'/g, '"');
      if (!newCustom.includes('[')) return StatusMapOrders[90];
      const jsonParse: IStatusCustom[] = JSON.parse(newCustom);
      let idStatus: number = 500;
      for (const statusCustom of jsonParse) {
        if (parseInt(statusCustom.idEstatus) < idStatus) idStatus = parseInt(statusCustom.idEstatus);
      }
      orderData = StatusMapOrders[idStatus];
      if (!orderData) return;
      updateQuantity(orderData.TypeOrder);
      return;
    });
    const slicedOrders = orders.slice(0, 4);
    const ordersData = slicedOrders.map((order: Order) => {
      let states = GetStatusByOrderLess(order);
      const information: OrderInformation = {
        OrderNumber: order.orderNumber || '',
        State: states.name,
        Color: states.color,
        Skus: order.lineItems.length,
        // @ts-ignore
        TotalPrice: formatCurrency(order.totalPrice.centAmount / 100) || 0,
      };
      return {
        orderNumber: order.orderNumber || '',
        status: information.State,
        colorStatus: states.color,
        articles: order.lineItems.length,
        total: formatCurrency(order.totalPrice?.centAmount / 100) || '',
        totalNumber: order.totalPrice.centAmount / 100,
      };
    });
    setTableOrders({
      Svg: <ComprasB2BIconBlue />,
      OrderStatus: dataTable,

      ordersData,
    });
  };

  const loadB2COrders = async () => {
    const loginEncrypt = getCookieValue(findCookie('login'));
    const login = decryptWithAES(loginEncrypt);
    const { Email, Password, BussinesUnitParent, ExternalId, CustomerId } = ctCredentials(login);
    console.log('BussinesUnitParent', BussinesUnitParent);

    let orders;

    try {
      let query = '';
      if (BussinesUnitParent.includes('d-')) {
        query = `custom( fields ( id_distribuidor in ("${BussinesUnitParent.split('-')[1]}") )) and orderState in ("Open") and paymentState in("Paid")`;
      } else {
        query = `custom( fields ( id_sucursal in ("${BussinesUnitParent}") )) and orderState in ("Open") and paymentState in("Paid")`;
      }
      debugger
      orders = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: 'b2c-store' })
        .orders()
        .get({
          queryArgs: {
            where: `${query}`,
            sort: 'createdAt desc',
            limit: 3,
          },
        })
        .execute();
      if (orders.statusCode !== 200) {
        notifyError('error al cargar las ordenes');
        return;
      }
    } catch (e: any) {
      notifyError('error al cargar las ordenes: ' + e.message);
      return;
    }

    setB2COrders(orders?.body?.results);

    const mappedOrders = mapB2COrders(orders?.body?.results);
    setCardsB2C(mappedOrders);
  };

  const mapB2COrders = (orders: Order[]) => {
    const mappedOrders: ICardOrdenesCompras[] = [];
    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    orders.forEach((order) => {
      // @ts-ignore
      const deliveredAtDate = new Date(order?.createdAt);
      deliveredAtDate.setDate(deliveredAtDate.getDate() + 1);
      const deliveredAt = deliveredAtDate.toLocaleDateString(
        'es-MX',
        // @ts-ignore
        dateOptions,
      );

      const mappedOrder: ICardOrdenesCompras = {
        main: <span><span>{order.lineItems.length}</span>Productos</span>,
        redirectsTo: `${CONST_REDIRECT.ECOMMERCE_B2C_DETAILS}?orderId=${order.orderNumber}`,
        texts: [
          { label: 'ID orden:', value: order.orderNumber || '' },
          { label: 'Cliente:', value: order.shippingAddress?.firstName || '' },
          { label: 'Entrega:', value: deliveredAt },
        ],
      };

      mappedOrders.push(mappedOrder);
    });
    return mappedOrders;
  };

  //fetch e-commerce orders
  useEffect(() => {
    loadB2COrders();
  }, []);

  useEffect(() => {
    if (context.appContext.orders.length) mapOrdersData();
  }, [context.appContext.orders]);

  useEffect(() => {
    if (context.appContext.shoppingLists.length) mapShoppingListData();
  }, [context.appContext.shoppingLists]);

  useEffect(() => {
    if (context.appContext.dataLimiteCredito) showRotoplasCreditNotificationStatus();
  }, [context.appContext.dataLimiteCredito]);

  useEffect(() => {
    console.log("ROLE: ", context.appContext.customer?.custom?.fields.roles);
    if (context.appContext.customer?.custom?.fields.roles === 'PDVOperatorR') {
      // router.push(CONST_REDIRECTS.HOME)
      router.push(CONST_REDIRECT.ORDERS);
    }
  }, [context.appContext.customer]);

  return (
    <MainLayout>
      {/* <WhatsAppBussiness /> */}
      <div className={styles['container']}>
        {/* Esther */}
        <div className={styles['slides-container']}>
          <div>
            <Skeleton isLoaded={context.appContext.isLoaded}>
              <Carousel />
            </Skeleton>
          </div>
          <div>
            <Skeleton isLoaded={context.appContext.isLoaded}>
              <ProductosPedidos
                customer={context.appContext.customer}
                bussinessUnits={context.appContext.bussinessUnits}
              />
            </Skeleton>
          </div>
        </div>
        {/* Martin */}
        <div className={styles['categories']}>
          <Skeleton type="title" isLoaded={context.appContext.isLoaded}>
            <h3 className={styles['categorie-title']}>Categorías</h3>
          </Skeleton>
          <div className={styles['container-categories']}>
            {context.appContext.categories?.map((categorie) => {
              return (
                <Skeleton isLoaded={context.appContext.isLoaded}>
                  <CardCategorie
                    name={categorie.name['es-MX']}
                    imageSource={categorie.custom?.fields['image']}
                    id={categorie.id}
                  />
                </Skeleton>
              );
            })}
          </div>
        </div>
        <div className={styles['cards-container']}>
          <div className={styles['item-cards']}>
            <Skeleton isLoaded={context.appContext.isLoaded}>
              <ComprasB2b
                Svg={tablesorders?.Svg!}
                OrderStatus={tablesorders?.OrderStatus!}
                ordersData={tablesorders?.ordersData || []}
              />
            </Skeleton>
          </div>
          <div className={styles['item-cards']} style={{ padding: paddingElement }}>
            <Skeleton isLoaded={context.appContext.isLoaded}>
              <>
                {context.appContext.customer?.custom?.fields.roles &&
                  rol[context.appContext.customer?.custom?.fields.roles]['seeShoppingLists'] && (
                    <HomeShoppingList rowData={shoppingListData || []} />
                  )}
              </>
            </Skeleton>
          </div>
          {context.appContext.customer?.custom?.fields.roles &&
            rol[context.appContext.customer?.custom?.fields.roles]['seeEcommerceOrders'] && (
              <div className={styles['item-cards']} style={{ padding: paddingElement }}>
                <Skeleton isLoaded={context.appContext.isLoaded}>
                  <>
                    <CardOrdenesCompras
                        title={'Nuevas solicitudes e-commerce'}
                        icon={<NuevasSolicitudesIcon />}
                        cards={cardsB2C}
                        redirectsTo={CONST_REDIRECT.ECOMMERCE_B2C}
                        emptyStateIcon={<B2CEmptyStateOrdersIcon/>}
                        emptyStateLabel='Sin solicitudes nuevas' />
                  </>
                </Skeleton>
              </div>
            )}
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
