import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './Header.module.scss';
import { CartIcon, SearchIcon } from '@/shared/icons';
import CompraRapidaDrawer from './CompraRapidaDrawer';
import { useRouter } from 'next/router';
import CustomBreadcrumb from './CustomBreadcrumb';
import { LogoIcon, HamburgerMenu, NotificationIcon } from '@/shared/icons';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';
import { rol } from '@/shared/roles/featureFlags';
import { PRODUCT_TYPE } from '@/shared/consts/CONST_PROJECT.json'
import type {
  ClientResponse,
  Customer,
  ProductProjection,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk';
import { Autocomplete, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';

import { apiRoot, ctCustomer } from '@/shared/client';
import { notifyError } from '../toast/Toast';


interface Option {
  idProduct: string;
  idVariant: string;
  key: string;
  name: string;
}

const Header = (props: { setIsOpenDrawer: any; cartItemsQuantity: any; customer: Customer | null}) => {
  const router = useRouter();
  const inputRef = useRef<any>();

  const [options, setOptions] = useState<Option[]>([]);
  const [results, setResults] = useState<ProductProjection[]>();
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState("")

  const sdk_getProducts = (
    querySearch: string,
  ): Promise<ClientResponse<ProductProjectionPagedSearchResponse> | string> => {
    // const loginEncrypt = getCookieValue(findCookie('login'));
    // const login = decryptWithAES(loginEncrypt);
    // const { Email, Password } = ctCredentials(login);

    return new Promise(async (resolve, reject) => {
      if (typeof querySearch !== 'string') return reject('');
      if (!querySearch?.trim()) return reject('');

      let fuzzyLevel: number = 0;
      if (querySearch.length > 5) fuzzyLevel = 2;
      if (querySearch.length >= 3 && querySearch.length < 5) fuzzyLevel = 1;
      if (querySearch.length < 3) fuzzyLevel = 0;

      try {
        const resProducts = await apiRoot
          .productProjections()
          .search()
          .get({
            queryArgs: {
              'text.es-MX': querySearch,
              fuzzy: true,
              fuzzyLevel,
              filter: [`productType.id: "${PRODUCT_TYPE}"`]
            },
          })
          .execute();

        resolve(resProducts);
      } catch (e: any) {
        reject('error: ' + e.message);
      }
    });
  };

  let idTimeout: any;
  const handleInputChange = (e: any, value: any) => {
    // @ts-ignore
    clearTimeout(idTimeout);
    setInput(value)
    // @ts-ignore
    idTimeout = setTimeout(async () => {
      const res = await sdk_getProducts(e.target.value);
      if (typeof res === 'string') {
        notifyError('error al obtener productos: ' + res);
        return;
      }
      if (res.statusCode !== 200) {
        notifyError('error al obtener productos');
        return;
      }
      setResults(res.body.results);
    }, 100);
  };

  useEffect(() => {
    if (results?.length === 0 || !results) return;

    let newOptions: Option[] = [];

    results.forEach((product) => {
      if (product.masterVariant.key)
        newOptions.push({
          idProduct: product.id.toString(),
          idVariant: product.masterVariant.id.toString(),
          key: product.masterVariant.key,
          name: product.masterVariant?.attributes?.find((attr) => attr.name === 'name')?.value['es-MX'] || '',
        });

      product.variants.forEach((variant) => {
        if (variant.key)
          newOptions.push({
            idProduct: product.id,
            idVariant: variant.id.toString(),
            key: variant.key,
            name: variant.attributes?.find((attr) => attr.name === 'name')?.value['es-MX'] || '',
          });
      });
    });

    setOptions(newOptions);
  }, [results]);

  return (
    <>
      <div className={styles['header']}>
        <div
          className={styles['hamburger-menu']}
          onClick={() => {
            props.setIsOpenDrawer(true);
          }}
        >
          <HamburgerMenu />
        </div>
        <div className={styles['logo']} onClick={()=>{
          router.push(CONST_REDIRECTS.HOME)
        }}>
          <LogoIcon />
        </div>
        <div className={styles['breadcrumb-container']}>
          <CustomBreadcrumb />
        </div>
        {props.customer?.custom?.fields.roles!=="PDVOperatorR" && 
        <>
        <div className={styles['search-input-container']}>
          <Autocomplete
            PaperComponent={({ children }) => <Paper style={{ width: '100%' }}>{children}</Paper>}
            size="small"
            ref={inputRef}
            disablePortal
            id="combo-box-demo"
            options={options}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            //add this line if we want all matching options from CT
            //otherwise it applies another filter to the res options
            //by name including substring in it
            filterOptions={(options) => options}
            onInputChange={handleInputChange}
            //which prop of Options are going to be displayed
            getOptionLabel={(option: Option) => option.name || option.key}
            onKeyDown={(e: any) => {
              //on enter, like an onSubmit button type
              if (e.keyCode === 13) {
                debugger
                const value = e.target.value;
                router.push(`${CONST_REDIRECTS.PLP}?querySearch=${value}`);
                setInput("")
                setOpen(false);
              }
            }}
            inputValue={input}
            onChange={(e: any, val: any) => {
              router.push('/pdp?productId=' + val.idProduct + '&variantId=' + val.idVariant + '&productKey=' + val.key);
            }}
            sx={{ width: '100%' }}
            noOptionsText="No hay opciones"
            renderInput={(params) => <TextField {...params} label="Buscar" />}
          />
          <div className={styles['search-icon']}>
            <SearchIcon />
          </div>
        </div>
        <div
          className={styles['cart']}
          onClick={() => {
            router.push('/cart');
          }}
        >
          {props.customer && rol[props.customer.custom?.fields?.roles]?.['createOrders'] && <CartIcon />}
          {props.cartItemsQuantity > 0 && <span>{props.cartItemsQuantity}</span>}
        </div>
        </>
        }
        <div className={styles['notification']}>
          <NotificationIcon />
        </div>
        <div className={styles['compra-rapida']}>
          {props.customer && rol[props.customer.custom?.fields?.roles]?.['createOrders'] && <CompraRapidaDrawer />}
        </div>
      </div>
      <div className={styles['header-breadcrumb']}>
        <CustomBreadcrumb />
      </div>
    </>
  );
};

export default Header;
