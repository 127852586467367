import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Button from '@/shared/components/button/Button';
import * as XLSX from 'xlsx';
import { useRouter } from 'next/router';
import { DownloadIcon, Upload } from '@/shared/icons';
import { crErrorToastStyles, crSuccessToastStyles, notifyError, notifySuccess } from '../../toast/Toast';
import { addProductToCartWithShippingAddress } from '@/shared/functions/commerceTools';
import { Cart, type BusinessUnit } from '@commercetools/platform-sdk';
import { getCredentials } from 'context/appContext/initialLoading';
import ModalCustumized from '@/shared/components/modal/Modal';
import { AppContext } from 'context/appContext/AppContextProvider';
import { apiRoot } from '@/shared/client';
import CONST from '@/shared/consts/CONST_PROJECT.json';
import axios from 'axios';

interface ISetEvents {
    setShowLoader: any;
    setComponent: any;
}

interface Response {
    Errors: Errors
    LineItems: LineItems
}

interface Errors {
    count: number;
    errors: string[];
}

interface LineItems {
    count: number;
    results: items[]
}

interface items {
    business: string;
    sku: string;
    quantity: number;
}


interface ItemsErrors {
    Errors: Errors,
    showModal: boolean
}

const initialItemsErrors: ItemsErrors = {
    Errors: { count: 0, errors: [] },
    showModal: false
}

const CompraByCsv = ({ setShowLoader, setComponent }: ISetEvents) => {
    const router = useRouter();
    const context = useContext(AppContext)
    const [droppedFile, setDroppedFile] = useState<File | null>(null);
    const [{ Errors, showModal }, setItemsErrors] = useState<ItemsErrors>(initialItemsErrors)
    const [tableData, setTableData] = useState<any[]>([]);
    const buttonCancel = 'Cancelar';
    const buttonUpload = 'Subir Archivo';



    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setDroppedFile(file);
        if (e.dataTransfer.items) {
            e.dataTransfer.items.clear();
        } else {
            e.dataTransfer.clearData();
        }
    };

    const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const resetDroppedFile = () => {
        setDroppedFile(null);
    };

    const handleInputFile = () => {
        const input = document.getElementById("input-file") || null;
        if (input) input.click();
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            setDroppedFile(e.target.files[0]);
        }
    };

    const addProductsToCart = async (quantity: number, sku: string, addressKey: string): Promise<{
        wentOk: boolean;
        cart: Cart | null;
        errMessage?: string | undefined;
    }> => {
        const loadProductsReq = await apiRoot.productProjections().get({
            queryArgs: {
                where: `masterVariant(sku in (${sku}))`,
                limit: 1
            }
        }).execute()

        const minimalquantity: number = loadProductsReq.body.results?.[0]?.masterVariant?.attributes?.find(attr => attr.name === "minimalquantity")?.value || 1
        console.log("loadProductsReq", loadProductsReq)
        if (quantity < minimalquantity) {
            return { wentOk: false, cart: null, errMessage: `Producto ${sku} no pudo ser agregado al carrito: la cantidad minima debe ser de ${minimalquantity}` }
        }

        return addProductToCartWithShippingAddress({
            quantity,
            sku,
            addressKey,
        }).then((resp) => {
            debugger
            if (!resp.wentOk) {

                return { wentOk: false, cart: null, errMessage: resp?.errMessage ?? `Producto ${sku} no pudo ser agregado al carrito` }
            }
            return resp
        }).catch((error: any) => {
            console.error('Error al agregar productos al carrito', error);
            return { wentOk: false, cart: null, errMessage: `Producto ${sku} no pudo ser agregado al carrito` }
        });
    };

    const handleUpload = async () => {
        try {
            setShowLoader(true);
            if (droppedFile) {
                let reader: any
                try {
                    reader = new FileReader();
                } catch (err: any) {
                    setShowLoader(false);
                    return notifyError(err.message)
                }
                reader.onload = async (event: any) => {
                    const data = event.target?.result;
                    if (droppedFile.name.endsWith('.csv')) {
                        // Read CSV File
                        const { BussinesUnitParent } = getCredentials();
                        const formData = new FormData()
                        formData.append("file", droppedFile);
                        const req = await axios.post(`${CONST.API_COMPRA_RAPIDA}/upload/compra/${BussinesUnitParent}`, formData, {
                            validateStatus: function(status) {
                                return status < 501; // Resolve only if the status code is less than 500
                            },
                        })
                        const response: Response = req.data
                        if (response.Errors.count > 0) {
                            setItemsErrors(opt => ({
                                ...opt,
                                Errors: {
                                    errors: response.Errors.errors,
                                    count: response.Errors.count,
                                },
                                showModal: true,
                            }))
                            setShowLoader(false);
                            return
                        }
                        let addCart
                        const errors = []
                        for (const item of response.LineItems.results) {
                            addCart = await addProductsToCart(item.quantity, item.sku, item.business)
                            if(!addCart.wentOk) errors.push(`-El producto ${item.sku} no se pudo agregar por el siguiente error: ${addCart.errMessage}`)
                        }
                        if(errors.length > 0 ){
                            notifyError(errors.join(","))
                            setShowLoader(false);
                            if (addCart?.cart) context.setCart(addCart?.cart)
                            setComponent('by-resumen');
                            return
                        }
                        // context.setCart(cartRes.cart)
                        notifySuccess('Productos agregados correctamente');
                        setShowLoader(false);
                        // debugger
                        if (addCart?.cart) context.setCart(addCart?.cart)
                        setComponent('by-resumen');
                    } else if (droppedFile.name.endsWith('.xlsx')) {
                        setShowLoader(false);
                        return notifyError("Formato de archivo no valido")
                    } else {
                        notifyError("Archivo no válido", crErrorToastStyles);
                    }
                };
                if (droppedFile.name.endsWith('csv')) {
                    reader.readAsText(droppedFile);
                } else if (droppedFile.name.endsWith('.xlsx')) {
                    reader.readAsBinaryString(droppedFile);
                }
            } else {
                throw Error("Por favor, adjunta tu archivo CSV. En caso de que no lo tengas disponible, puedes descargar el formato")
            }
        } catch (err: any) {
            console.error("ERR  OR:", err);
            setShowLoader(false);
            notifyError(err.message ?? err.Error)
        }
    };

    return (
        <>
            <ModalCustumized
                className={styles['shopping-list-modal']}
                label1=""
                labelButton1=''
                labelButton2="Cerrar"
                variant={"secondary"}
                open={showModal}
                onClose={() => {
                    setItemsErrors(opts => ({
                        ...opts,
                        showModal: false
                    }))
                }}
            >
                <div style={{ width: "90%", margin: "0 auto", }}>
                    <h3 >Se presentaron los siguientes errores:</h3>
                    <hr />
                    <ul style={{ listStyle: "disc", maxHeight: "400px", overflow: "scroll" }}>
                        {
                            Errors.errors.map((item, i) => {
                                return <li style={{ color: "red" }} key={i}>
                                    {item}
                                </li>
                            })
                        }
                    </ul>
                </div>
            </ModalCustumized>
            <div className={styles['container-headers']}>
                <div className={styles['header']}>
                    <div>
                        <div
                            onClick={() => {
                                setComponent('select');
                            }}
                            className={styles['container-close-icon']}
                        >
                            <p className={styles['text-compra-rapida-icon']}>&lt;</p>
                            <p className={styles['text-compra-rapida']}>Comprar con archivo CSV</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['container-body']}>
                <div className={styles['container-body-description']}>
                    <span className={styles['text-entry']}>
                        Carga un documento CSV con tu lista de compra para agregar productos a tu carrito de manera masiva.
                    </span>
                </div>
                <div className={styles['container-body-upload']}>
                    <div onDrop={(e) => handleDrop(e)} onDragOver={(e) => allowDrop(e)} onClick={handleInputFile} className={styles['container-upload']}>
                        {droppedFile && <p className={styles['text-upload']}>{droppedFile.name}</p>}
                        {!droppedFile && (
                            <>
                                <input type='file' accept='.csv, .xlsx' name='input-file' id='input-file' onChange={handleFileChange}></input>
                                <Upload />
                                <p className={styles['text-upload']}>
                                    Arrastra y suelta{' '}
                                    <span className={styles['span-upload']}>aquí el archivo o haz clic para abrir el buscador</span>
                                </p>
                            </>
                        )}
                    </div>
                    <div className={styles['container-buttons']}>
                        <div className={styles['cancel-button']}>
                            <Button
                                onClick={resetDroppedFile}
                            >
                                {buttonCancel}
                            </Button>
                        </div>
                        <div className={styles['upload-button']}>
                            <Button
                                variant="secondary"
                                onClick={async () => {
                                    await handleUpload();
                                }}
                            >
                                {buttonUpload}
                            </Button>
                        </div>
                    </div>
                    <span className={styles['text-download']}>
                        <p>¿No tienes la plantilla?</p>
                        <a
                            style={{ color: '#165EEB' }}
                            href={CONST.DOWNLOAD_PDF}
                            download="comprarapida.csv"
                        >
                            Descarga el formato CSV <DownloadIcon />
                        </a>
                    </span>
                </div>
            </div>
        </>
    );
};

export default CompraByCsv;
