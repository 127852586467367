import {
  BusinessUnit,
  Cart,
  ClientResponse,
  MyCartAddItemShippingAddressAction,
  MyCartAddLineItemAction,
  MyCartUpdate,
  MyCartUpdateItemShippingAddressAction,
  Product,
} from '@commercetools/platform-sdk';
import { api_getBusinessUnits, api_getBusinessUnits_ctapi } from '../api_calls/businessUnit';
import { api_createCart, api_getActiveCart, api_postMeCart } from '../api_calls/cart';
import { apiRoot, ctCustomer } from '../client';
import { ctCredentials } from './ctCredentiales';
import { ICustomField } from '../interfaces/IShoppingList';
import { decryptWithAES } from './encrypt';
import { ByProjectKeyStandalonePricesKeyByKeyRequestBuilder } from '@commercetools/platform-sdk/dist/declarations/src/generated/client/standalone-prices/by-project-key-standalone-prices-key-by-key-request-builder';
import CONST_PROJECT from '@/shared/consts/CONST_PROJECT.json';
import { getCredentials } from 'context/appContext/initialLoading';
import { getMappedLineItem } from './arrays';
import { cartItem } from 'types/cart';

const getCartBodyRequestRemoveAllLineItem = ({ version, lineItemId }: { version: number; lineItemId: string }) => ({
  version,
  actions: [
    {
      action: 'removeLineItem',
      lineItemId,
    },
  ],
});

export const loadCart = async (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const res = await api_getActiveCart();
    if (res.status !== 200) {
      console.error('ShoppingCart loadCart error:', res);
      alert('algo paso al obtener el carrito activo');
      reject('');
    }
    const cart = res.data.results[0] || null;
    resolve(cart);
  });
};

export const sdk_loadCart = async (): Promise<Cart> => {
  return new Promise(async (resolve, reject) => {
    const { Email, Password, BussinesUnitParent } = getCredentials();
    let res;
    try {
      res = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
        .me()
        .carts()
        .get({ queryArgs: { where: 'cartState in ("Active")' } })
        .execute();
    } catch (e) {
      console.error(e);
      return;
    }
    resolve(res.body.results[0]);
  });
};

export const loadBusinessUnits = async (): Promise<
  { shippingAdressId: string; state: string; bussinessUnit: BusinessUnit }[]
> => {
  return new Promise(async (resolve, reject): Promise<ICustomBusinessUnit | undefined> => {
    const { Email, Password, BussinesUnitParent } = getCredentials();
    // const res = await apiRoot
    //     .businessUnits()
    //     .get({
    //         queryArgs: {
    //             limit: 500,
    //             where: `parentUnit (key in ("${BussinesUnitParent}"))`,
    //         },
    //     })
    //     .execute();
    const res = await ctCustomer(Email, Password)
      .me()
      .businessUnits()
      .get({
        queryArgs: {
          limit: 500,
          where: `topLevelUnit (key in ("${BussinesUnitParent.trim()}"))`,
        },
      })
      .execute();
    if (!res.statusCode || res.statusCode >= 300) return;
    const bussinesActive = res.body.results.filter(
      (bussines) => bussines.status !== 'Inactive' && !bussines.key.includes('d-'),
    );
    const businessUnits = bussinesActive.map((bussinesUnit, index: any) => {
      return {
        shippingAdressId: bussinesUnit.id,
        state: bussinesUnit.name,
        bussinessUnit: bussinesUnit,
      };
    });
    resolve(businessUnits);
  });
};

export type ICustomBusinessUnit = {
  shippingAdressId: string;
  state: string;
  bussinessUnit: BusinessUnit;
};

export const sdk_loadMyBusinessUnit = async (): Promise<ICustomBusinessUnit> => {
  return new Promise(async (resolve, reject) => {
    const { Email, Password, BussinesUnitParent } = getCredentials();
    const resBusinessUnits = await ctCustomer(Email, Password).me().businessUnits().get().execute();
    // const resBusinessUnits = await apiRoot
    //     .businessUnits()
    //     .get({
    //         queryArgs: {
    //             limit: 500,
    //             where: `parentUnit (key in ("${BussinesUnitParent}"))`,
    //         },
    //     })
    //     .execute();
    if (!resBusinessUnits.statusCode || resBusinessUnits.statusCode >= 300) {
      console.error('resBusinessUnits error:', resBusinessUnits);
      reject('error al cargar unidad de negocio');
      return;
    }
    const bussinessUnit = resBusinessUnits.body.results[0];
    resolve({
      shippingAdressId: bussinessUnit.id,
      state: bussinessUnit.addresses[0]?.state || 'state',
      bussinessUnit,
    });
  });
};

export const loadBusinessUnits_ctapi = async (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    const res = await api_getBusinessUnits_ctapi();
    if (res.statusCode !== 200) {
      console.error('ShoppingCart loadCart resBusinessUnits error:', res);
      alert('algo paso al obtener el carrito activo');
      reject('');
    }

    const businessUnits = res.body.results.map((bussinesUnit: any, index: any) => {
      return {
        shippingAdressId: bussinesUnit.id,
        state: bussinesUnit.addresses[0]?.state,
        bussinessUnit: res.body.results[index],
      };
    });
    resolve(businessUnits);
  });
};

export const addProductToCartWithShippingAddress = ({
  quantity,
  product,
  addressKey,
  variantId,
  sku,
}: {
  quantity: number;
  product?: Product;
  addressKey: string;
  variantId?: number;
  sku?: string;
}): Promise<{ wentOk: boolean; cart: Cart | null; errMessage?: string }> => {
  return new Promise(async (resolve, reject) => {
    //perdoname Donald, el vicio me hizo asi
    // @ts-ignore
    if (!sku && product?.sku) {
      // @ts-ignore
      sku = product.sku;
    }

    // @ts-ignore
    if (!variantId && product?.variantId) {
      // @ts-ignore
      variantId = product.variantId;
    }

    if (sku) {
      const standalonePrice = await getStandalonePriceByBranch({
        distributionChannel: 'general',
        keyBussinessUnit: addressKey,
        sku,
      });
      if (!standalonePrice.body.value.centAmount) {
        resolve({
          wentOk: false,
          errMessage: 'No se puede agregar un producto con precio de 0',
          cart: null,
        });
        return;
      }
    } else if (product) {
      //getting sku with productId
      const { Email, Password } = getCredentials();
      const productDetailsRes = await ctCustomer(Email, Password).products().withId({ ID: product.id }).get().execute();
      const sku = productDetailsRes.body.masterData.current.masterVariant.sku;
      if (!sku) {
        resolve({
          wentOk: false,
          errMessage: 'no se proporciono sku, sku no encontrado de variante maestra',
          cart: null,
        });
        return;
      }
      const standalonePrice = await getStandalonePriceByBranch({
        distributionChannel: 'general',
        keyBussinessUnit: addressKey,
        sku,
      });
      if (!standalonePrice.body.value.centAmount)
        resolve({
          wentOk: false,
          errMessage: 'no se puede agregar un producto con precio de 0',
          cart: null,
        });
    }

    const { Email, Password, BussinesUnitParent, CustomerId } = getCredentials();

    let cart = await sdk_loadCart();
    if (!cart) {
      // const resCreateCart = await api_createCart({ currency: 'MXN' });

      let resCreateCart;
      try {
        const bussinessUnits = await ctCustomer(Email, Password)
          .businessUnits()
          .withKey({ key: BussinesUnitParent })
          .get()
          .execute();

        if (!bussinessUnits.body.addresses.length) reject('bussiness unit no cuenta con address');

        const bussinesbyIdCustomer = await apiRoot
          .asAssociate()
          .withAssociateIdValue({ associateId: CustomerId })
          .businessUnits()
          .get()
          .execute();

        resCreateCart = await ctCustomer(Email, Password)
          .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
          .me()
          .carts()
          .post({
            body: {
              currency: 'MXN',
              // shippingMode: 'Multiple',
              shippingAddress: {
                ...bussinessUnits.body.addresses[0],
              },
              billingAddress: {
                ...bussinessUnits.body.addresses[0],
              },
              businessUnit: {
                typeId: 'business-unit',
                key: bussinesbyIdCustomer.body.results[0].key,
              },
            },
          })
          .execute();
      } catch (e) {
        console.error('error al crear carrito', e);
        alert('algo salio mal al crear carrito');
        return;
      }

      cart = resCreateCart?.body;
    }
    try {
      const itemToAdd = cart.lineItems.find(
        (lineItem) => lineItem.variant.sku === sku || lineItem.variant.id === variantId,
      );
      if (itemToAdd) {
        const mappedItem = getMappedLineItem(itemToAdd);
        gtag('event', 'add_to_cart', {
          currency: 'MXN',
          value: parseFloat(((itemToAdd?.price.value.centAmount || 0) / 100).toFixed(2)),
          items: mappedItem,
        });
      }
    } catch (e) {
      console.error('Google Tag Manager error when adding product in cart: ', e);
    }

    let productId = '';
    if (product) {
      productId = product.id;
    }

    //load business units

    const bussinessUnitObj = await loadBusinessUnits();

    //bussinessObject es el bussiness unit que se mando
    const bussinessObject = bussinessUnitObj.find((b: any) => {
      return b.bussinessUnit.key === addressKey;
    });
    if (!bussinessObject) {
      resolve({ wentOk: false, errMessage: 'unidad de negocio de cliente no esta relacionada', cart: null });
      return;
    }

    let resAddLineItem;
    let resAddItemShippingAddressToCart;
    let bodyAddLineItem: MyCartAddLineItemAction;

    const bussinessUnitAddress = bussinessObject.bussinessUnit.addresses[0];

    const bodyAddShippingAddressToCart: MyCartAddItemShippingAddressAction = {
      action: 'addItemShippingAddress',
      address: {
        key: bussinessObject.bussinessUnit.key,
        title: bussinessUnitAddress.title || '',
        salutation: bussinessUnitAddress.salutation || '',
        firstName: bussinessUnitAddress.firstName || '',
        lastName: bussinessUnitAddress.lastName || '',
        streetName: bussinessUnitAddress.streetName || '',
        streetNumber: bussinessUnitAddress.streetNumber || '',
        additionalStreetInfo: bussinessUnitAddress.additionalStreetInfo || '',
        postalCode: bussinessUnitAddress.postalCode || '',
        city: bussinessUnitAddress.city || '',
        region: bussinessUnitAddress.region || '',
        state: bussinessUnitAddress.state || '',
        country: bussinessUnitAddress.country || '',
        company: bussinessUnitAddress.company || '',
        department: bussinessUnitAddress.department || '',
        building: bussinessUnitAddress.building || '',
        apartment: bussinessUnitAddress.apartment || '',
        pOBox: bussinessUnitAddress.pOBox || '',
        phone: bussinessUnitAddress.phone || '',
        mobile: bussinessUnitAddress.mobile || '',
        email: bussinessUnitAddress.email || '',
        fax: bussinessUnitAddress.fax || '',
        additionalAddressInfo: bussinessUnitAddress.additionalAddressInfo || '',
        externalId: bussinessUnitAddress.externalId || CustomerId,
      },
    };
    try {
      if (sku) {
        bodyAddLineItem = {
          action: 'addLineItem',
          sku,
          quantity,
          distributionChannel: {
            typeId: 'channel',
            key: `${addressKey}-${'general'}`, // <key-sucursal>-<distribucion: construccion | general | gobierno|>
          },
          supplyChannel: {
            typeId: 'channel',
            key: `${addressKey}-${'general'}`, // <key-sucursal>-<distribucion: construccion | general | gobierno|>
          },
          shippingDetails: {
            targets: [
              {
                addressKey,
                quantity,
              },
            ],
          },
        };
      } else {
        bodyAddLineItem = {
          action: 'addLineItem',
          productId,
          variantId,
          quantity,
          distributionChannel: {
            typeId: 'channel',
            key: `${addressKey}-${'general'}`, // <key-sucursal>-<distribucion: construccion | general | gobierno|>
          },
          supplyChannel: {
            typeId: 'channel',
            key: `${addressKey}-${'general'}`, // <key-sucursal>-<distribucion: construccion | general | gobierno|>
          },
          shippingDetails: {
            targets: [
              {
                addressKey: bussinessObject.bussinessUnit.key,
                quantity,
                // shippingMethodKey:bussinessObject.bussinessUnit.key
              },
            ],
          },
        };
      }

      const foundAddressKeyInCartShippingAddress = cart.itemShippingAddresses.find(
        (addr) => addr.key === bussinessObject.bussinessUnit.key,
      );

      if (!foundAddressKeyInCartShippingAddress) {
        resAddLineItem = await ctCustomer(Email, Password)
          .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
          .me()
          .carts()
          .withId({ ID: cart.id })
          .post({
            body: {
              version: cart.version,
              actions: [bodyAddShippingAddressToCart],
            },
          })
          .execute();

        cart = resAddLineItem.body;
      }

      resAddItemShippingAddressToCart = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
        .me()
        .carts()
        .withId({ ID: cart.id })
        .post({
          body: {
            version: cart.version,
            actions: [bodyAddLineItem],
          },
        })
        .execute();

      cart = resAddItemShippingAddressToCart.body;
    } catch (e: any) {
      debugger;
      console.error('error al añadir producto', e);
      const errMessage = e.message ?? e;
      reject({ wentOk: false, errMessage });
    }
    resolve({ wentOk: true, cart });
  });
};

export const removeProductToCartWithShippingAddress = async ({
  quantity,
  addressKey,
  lineItemId,
}: {
  quantity: number;
  addressKey: string;
  lineItemId: string;
}): Promise<{ wentOk: boolean; cart: Cart | null }> => {
  return new Promise(async (resolve, reject) => {
    let cart = await sdk_loadCart();

    const { Email, Password, BussinesUnitParent } = getCredentials();
    if (!cart) {
      alert('not active cart');
      reject({ wentOk: false, cart: null });
    }
    const itemToRemove = cart.lineItems.find((lineItem) => lineItem.id === lineItemId);
    if (!itemToRemove) {
      console.error(
        'remove product error:',
        `GTM not found lineItem with Id ${lineItemId} on Cart with Id: ${cart.id}`,
      );
      return;
    }
    const item = getMappedLineItem(itemToRemove);
    gtag('event', 'remove_from_cart', {
      currency: 'MXN',
      value: item.price,
      items: [item],
    });

    try {
      const res = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
        .carts()
        .withId({ ID: cart.id })
        .post({
          body: {
            version: cart.version,
            actions: [
              {
                action: 'removeLineItem',
                lineItemId,
                quantity,
                shippingDetailsToRemove: {
                  targets: [
                    {
                      addressKey,
                      quantity,
                    },
                  ],
                },
              },
            ],
          },
        })
        .execute();
      cart = res.body;
    } catch (e) {
      console.error(e);
      resolve({ wentOk: false, cart: null });
    }
    resolve({ wentOk: true, cart });
  });
};

export const removeAllProductFromCart = async ({
  lineItemId,
}: {
  lineItemId: string;
}): Promise<{ wentOk: boolean; cart: Cart | null }> => {
  return new Promise(async (resolve, reject) => {
    let cart = await loadCart();
    if (cart === null) {
      alert('not active cart');
      reject({ wentOk: false, cart: null });
    }
    const cartId = cart.id;
    let cartVersion = cart.version;

    const bodyRequestRemoveAllLineItems = getCartBodyRequestRemoveAllLineItem({ lineItemId, version: cartVersion });
    const resRemoveAllLineItem = await api_postMeCart(cartId, bodyRequestRemoveAllLineItems);
    cart = resRemoveAllLineItem.data;
    resolve({ wentOk: true, cart });
  });
};

export const addProductsToCartShippingList = async ({ shippingListId }: { shippingListId: string }) => {
  try {
    const { Email, Password, BussinesUnitParent } = getCredentials();
    const customer = ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent });
    const shoppingList = await customer
      .me()
      .shoppingLists()
      .withId({ ID: shippingListId })
      .get({
        queryArgs: {
          expand: 'lineItems[*].variant',
        },
      })
      .execute();
    if (!shoppingList.statusCode || shoppingList.statusCode >= 300) {
      return { message: 'Hubo un error al obtener listas de compras' };
    }
    if (shoppingList.body.lineItems.length <= 0) return { message: 'No hay productos para agregar a tu carrito' };
    let cartRes;
    for (const line of shoppingList.body.lineItems) {
      const fieldsItems = line.custom?.fields['sucursalItemReturn'] || [];

      for (const item of fieldsItems) {
        const field = JSON.parse(item);
        cartRes = await addProductToCartWithShippingAddress({
          quantity: parseInt(field.quantity),
          sku: line.variant?.sku,
          addressKey: field.idSucursal,
        });
      }
    }
    debugger;
    return { message: '', cartRes };
  } catch (err: any) {
    console.error(err);
    return { message: err.message };
  }
};

export const getStandalonePriceByBranch = async ({
  keyBussinessUnit,
  distributionChannel,
  sku,
}: {
  keyBussinessUnit: string;
  distributionChannel: string;
  sku: string;
}) => {
  const resStandalonePrice = await apiRoot
    .standalonePrices()
    .withKey({ key: `${keyBussinessUnit}-${distributionChannel}-${sku}` })
    .get()
    .execute();
  return resStandalonePrice;
};

export const addProductsToCartOrder = async ({ orderId }: { orderId: string }) => {
  // try {
  const { Email, Password, BussinesUnitParent } = getCredentials();

  const customer = ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent });

  const orders = await customer
    .orders()
    .withId({ ID: orderId })
    .get({
      queryArgs: {
        expand: 'lineItems[*].variant',
      },
    })
    .execute();

  if (!orders.statusCode || orders.statusCode >= 300) {
    return { message: 'Algo salió mal' };
  }

  const bussinessUnitObj = await loadBusinessUnits();
  const errors: string[] = [];

  let cartRes: {
    wentOk: boolean;
    cart: Cart | null;
    errMessage?: string | undefined;
  } | null = null;
  for (const line of orders.body.lineItems) {
    for (const item of line?.shippingDetails?.targets ?? []) {
      const bussinessObject = bussinessUnitObj.find((business: any) => business.bussinessUnit.key === item.addressKey);
      debugger;
      try {
        cartRes = await addProductToCartWithShippingAddress({
          quantity: item.quantity,
          sku: line.variant?.sku,
          addressKey: bussinessObject?.bussinessUnit?.key || 'addressKey',
        });
      } catch (err: any) {
        debugger;
        errors.push(line.variant.sku ?? '');
        continue;
      }
    }
  }

  debugger;
  return { message: errors.length > 0 ? errors : undefined, cartRes };
  // } catch (err: any) {
  //     console.error(err);
  //     return { message: err.message };
  // }
};
